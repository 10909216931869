<template>
  <el-container>
    <el-header height="60">
      <el-button style="float: right; padding: 5px" type="text" @click="drawCircle">绘制圆形</el-button>
      <el-button style="float: right; padding: 5px" type="text" @click="onPathNavigator">路径巡航</el-button>
    </el-header>
    <el-main>
      <div id="container" class="text item amap-wrapper" />
    </el-main>

    <el-dialog :visible.sync="showPositionDialog" width="30%" center>
      <el-card class="box-card" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <div slot="header" class="clearfix">
          <span>选中的坐标</span>
          <el-button style="float: right; padding: 10px" type="text" @click="onSavePosition">保存坐标</el-button>
        </div>
        <div class="text item">
          <span style="color: red">({{ positionForm.lng }}, {{ positionForm.lat }})</span>
        </div>
      </el-card>
    </el-dialog>
  </el-container>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import { addGeoPoint, getGeoPoint } from '@/api/map'

export default {
  name: 'Map',
  data() {
    return {
      amap: null,
      plugins: ['Scale'],
      mapCenter: [114.0000, 30.0000],
      // zoom=6 的比例尺为 100km
      zoom: 6,
      province: '',
      provinces: [],
      city: '',
      citys: [],
      district: '',
      districts: [],
      path1: [{
        path: [
          [116.361904, 39.913423],
          [116.367904, 39.913423]
        ]
      }],
      path: [],
      showPositionDialog: false,
      positionForm: {
        lng: null,
        lat: null
      },
      mapKeys: {
        securityJsCode: '983d6ee43bab3edf3693e91508f94aa9',
        key: '7b75ab2839ce68b884c7a682501ea774'
      }
    }
  },
  mounted() {
    this.initAMap()
  },
  unmounted() {
    this.map?.destroy()
  },
  created() {
    document.title = '地图'
  },
  methods: {
    initAMap() {
      window._AMapSecurityConfig = {
        securityJsCode: this.mapKeys.securityJsCode
      }
      AMapLoader.load({
        key: this.mapKeys.key, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '1.4.15', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar',
          'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.MassMarks', 'AMap.Size',
          'AMap.Pixel'], // 需要使用的的插件列表，如比例尺'AMap.Scale'，支持添加多个如：['...','...']
        AMapUI: { // 重点就是这个
          version: '1.0',
          plugins: ['misc/PathSimplifier', 'overlay/SimpleMarker']// SimpleMarker设置自定义图标，PathSimplifier轨迹展示组件
        }
      }).then((AMap) => {
        // 设置地图容器id
        this.map = new AMap.Map('container', {
          viewMode: '2D', // 是否为3D地图模式
          zoom: 6, // 初始化地图级别
          center: [104.065753, 30.657462] // 初始化地图中心点位置
        })

        this.map.on('click', (e) => {
          // 获取经纬度
          this.positionForm.lng = e.lnglat.getLng()
          this.positionForm.lat = e.lnglat.getLat()
          this.showPositionDialog = true
        })
      }).catch((e) => {
        console.log(e)
      })
    },
    zoomchange(e) {
      console.log('当前缩放级别: ' + this.amap.getZoom())
    },
    // 轨迹巡航
    loadPathSimplifier() {
      AMapUI.load(['ui/misc/PathSimplifier'], (PathSimplifier) => {
        if (!PathSimplifier.supportCanvas) {
          alert('当前环境不支持 Canvas！')
          return
        }
        // 创建组件实例
        var pathSimplifierIns = new PathSimplifier({
          map: this.map,
          zIndex: 100, // 图层叠加顺序
          data: this.path, // 巡航路径
          // 获取巡航路径中的路径坐标数组
          getPath: (pathData, pathIndex) => {
            return pathData.path
          }
        })
        // 创建巡航器
        var pathNavigator = pathSimplifierIns.createPathNavigator(0, {
          loop: false, // 是否循环
          speed: 2000 // 速度(km/h)
        })
        pathNavigator.start()
      })
    },
    getDistrict(map) {
      // 创建行政区查询对象
      var district = new AMap.DistrictSearch({
        // 返回行政区边界坐标等具体信息
        extensions: 'all',
        // 设置查询行政区级别为 区
        level: 'street',
        subdistrict: 3
      })

      this.zoom = 11
      const area = '双流'
      district.search(area, function(status, result) {
        var bounds = result.districtList[0].boundaries
        var polygons = []
        if (bounds) {
          for (var i = 0, l = bounds.length; i < l; i++) {
            // 生成行政区划polygon
            var polygon = new AMap.Polygon({
              map: map,
              strokeWeight: 1,
              path: bounds[i],
              fillOpacity: 0.7,
              fillColor: '#CCF3FF',
              strokeColor: '#CC66CC'
            })
            polygons.push(polygon)
          }

          // 地图自适应
          map.setFitView()
        }
      })
    },
    drawCircle1() {
      this.$message.info('加载路径')
      getGeoPoint().then(resp => {
        if (resp.code === 0) {
          const pathList = resp.data
          if (pathList.length === 0) {
            return
          }

          this.path = pathList
          AMapUI.load(['ui/misc/PathSimplifier'], (PathSimplifier) => {
            if (!PathSimplifier.supportCanvas) {
              alert('当前环境不支持 Canvas！')
              return
            }
            // 创建组件实例
            var pathSimplifierIns = new PathSimplifier({
              map: this.map,
              zIndex: 100, // 图层叠加顺序
              data: this.path, // 巡航路径
              // 获取巡航路径中的路径坐标数组
              getPath: (pathData, pathIndex) => {
                return pathData.path
              }
            })
            // 创建巡航器
            var pathNavigator = pathSimplifierIns.createPathNavigator(0, {
              loop: false, // 是否循环
              speed: 2000 // 速度(km/h)
            })
            pathNavigator.start()
          })
        }
      })
    },
    drawCircle() {
      // 设置圆形位置
      var center = new AMap.LngLat(104.065753, 30.657462)
      // 设置圆的半径大小
      var radius = 1000
      // 创建圆形 Circle 实例
      var circle = new AMap.Circle({
        center: center, // 圆心
        radius: radius, // 半径
        borderWeight: 3, // 描边的宽度
        strokeColor: '#ff3333', // 轮廓线颜色
        strokeOpacity: 1, // 轮廓线透明度
        strokeWeight: 1, // 轮廓线宽度
        fillOpacity: 0.4, // 圆形填充透明度
        strokeStyle: 'line', // 轮廓线样式
        strokeDasharray: [10, 10],
        fillColor: '#1791fc', // 圆形填充颜色
        zIndex: 50 // 圆形的叠加顺序
      })

      // 圆形 Circle 对象添加到 Map
      this.map.add(circle)
      // 根据覆盖物范围调整视野
      this.map.setFitView([circle])

      // 鼠标移入事件
      circle.on('mouseover', function() {
        console.log('鼠标移入')
      })

      var that = this.map
      // 引入圆形编辑器插件
      this.map.plugin(['AMap.CircleEditor'], function() {
        // 实例化圆形编辑器，传入地图实例和要进行编辑的圆形实例
        var circleEditor = new AMap.CircleEditor(that, circle)
        // 开启编辑模式
        circleEditor.open()
      })
    },
    onSavePosition() {
      this.showPositionDialog = false
      addGeoPoint(this.positionForm).then(resp => {
        if (resp.code !== 0) {
          this.$message.info('添加坐标失败')
        }
      })
    },
    onPathNavigator() {
      getGeoPoint().then(resp => {
        if (resp.code === 0) {
          const pathList = resp.data
          if (pathList.length === 0) {
            return
          }

          this.path = pathList
          AMapUI.load(['ui/misc/PathSimplifier'], (PathSimplifier) => {
            if (!PathSimplifier.supportCanvas) {
              alert('当前环境不支持 Canvas！')
              return
            }
            // 创建组件实例
            var pathSimplifierIns = new PathSimplifier({
              map: this.map,
              zIndex: 100, // 图层叠加顺序
              data: this.path, // 巡航路径
              // 获取巡航路径中的路径坐标数组
              getPath: (pathData, pathIndex) => {
                return pathData.path
              }
            })
            // 创建巡航器
            var pathNavigator = pathSimplifierIns.createPathNavigator(0, {
              loop: false, // 是否循环
              speed: 2000 // 速度(km/h)
            })
            pathNavigator.start()
          })
        }
      })
    }
  }
}
</script>

<style>
.amap-wrapper {
  width: 100%;
  height: 600px;
}

.movie-list {
  padding-top: 15px;
  padding-left: 3%;
  padding-right: 3%;
}
</style>
